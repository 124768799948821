import Input from "../UI/input";
import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import useInput from "../hooks/use-Input";
import instance from "../../config/config";
import AuthContext from "../store/authContext";
import Error from "../UI/Error";
import Navbar from "../Layouts/Navbar";

const isNotEmpty = (value) => {
  return value.trim() !== "";
};

const isEmail = (value) => {
  var reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (reg.test(value) === false) {
    return false;
  } else {
    return true;
  }
};
const isMobile = (val) => {
  if (/^\d{10}$/.test(val)) {
    return true;
  } else {
    return false;
  }
};

const BdmanagerForm = (props) => {
  const [status, setStatus] = useState(false);
  const [error, setError] = useState("");
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const history = useHistory();
  const {
    value: enteredName,
    hasError: nameInputHasError,
    valueCHangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    isValid: enteredNameIsValid,
    reset: resetNameInput,
  } = useInput(isNotEmpty);

  const {
    value: enteredEmail,
    hasError: emailInputHasError,
    valueCHangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    isValid: enteredEmailIsValid,
    reset: resetEmailInput,
  } = useInput(isEmail);

  const {
    value: enteredPassword,
    hasError: passwordInputHasError,
    valueCHangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    isValid: enteredPasswordIsValid,
    reset: resetPasswordInput,
  } = useInput(isNotEmpty);
  const {
    value: enteredMobile,
    hasError: mobileInputHasError,
    valueCHangeHandler: mobileChangeHandler,
    inputBlurHandler: mobileBlurHandler,
    isValid: enteredMobileIsValid,
    reset: resetMobileInput,
  } = useInput(isMobile);
  const statusManageHandler = () => {
    setStatus(!status);
  };

  let formIsValid = false;
  if (
    enteredNameIsValid &&
    enteredEmailIsValid &&
    enteredMobileIsValid &&
    enteredPasswordIsValid
  ) {
    formIsValid = true;
  }
  const submitHandler = (event) => {
    event.preventDefault();
    if (!enteredNameIsValid && !enteredEmailIsValid && !enteredMobileIsValid) {
      return;
    }

    const bdManager = {
      name: enteredName,
      email: enteredEmail,
      password: enteredPassword,
      mobile: enteredMobile,
      active: status,
    };
    let headers = {
      "x-access-token": token,
      "Content-Type": "application/json",
    };
    instance
      .post("/bd/bdmanagers", { bdManager }, { headers: headers })
      .then((response) => {
        console.log(response);
        history.push("/Bdmanagers");
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.response.data.message || "Something went wrong!");
      });
  };
  const style = (errorState) => {
    if (errorState) {
      return "form-group invalid";
    } else {
      return "form-group";
    }
  };
  return (
    <>
      <Navbar />
      <form
        onSubmit={submitHandler}
        className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
      >
        {error && <Error err={error} />}
        <div className="card h-100">
          <div className="card-body">
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 mb-3col-md-12 col-sm-12 col-12">
                <h5 className="mb-2 text-dark border-bottom">Add Manager</h5>
              </div>

              <div className="col-xl-12 p-2 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className={style(nameInputHasError)}>
                  <Input
                    onChange={nameChangeHandler}
                    onBlur={nameBlurHandler}
                    label="Manager Name"
                    input={{
                      type: "text",
                      value: enteredName,
                    }}
                  />
                  {nameInputHasError && (
                    <p className="text-danger"> Name Cannot Be Empty</p>
                  )}
                </div>
              </div>
              <div className="col-xl-12 p-2 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className={style(emailInputHasError)}>
                  <Input
                    onChange={emailChangeHandler}
                    onBlur={emailBlurHandler}
                    label="Manager Email"
                    input={{
                      type: "text",
                    }}
                  />
                  {emailInputHasError && (
                    <p className="text-danger"> Email is Not Valid</p>
                  )}
                </div>
              </div>
              <div className="col-xl-12 p-2 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className={style(passwordInputHasError)}>
                  <Input
                    onChange={passwordChangeHandler}
                    onBlur={passwordBlurHandler}
                    label="Manager Password"
                    input={{
                      type: "password",
                    }}
                  />
                  {passwordInputHasError && (
                    <p className="text-danger"> Password cannot Be Empty</p>
                  )}
                </div>
              </div>
              <div className="col-xl-12 p-2 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className={style(mobileInputHasError)}>
                  <Input
                    onChange={mobileChangeHandler}
                    onBlur={mobileBlurHandler}
                    label="Manager Mobile"
                    input={{
                      type: "tel",
                    }}
                  />
                  {mobileInputHasError && (
                    <p className="text-danger"> Invalid Mobile Number</p>
                  )}
                </div>
              </div>

              <div className="col-xl-12 p-2 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <label htmlFor="status">Status</label>
                  {status === false && (
                    <div className="form-check form-switch">
                      <input
                        onClick={statusManageHandler}
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                      />
                      <span className="py-0 px-1 bg-danger rounded text-white">
                        Inactive
                      </span>
                    </div>
                  )}
                  {status === true && (
                    <div className="form-check form-switch">
                      <input
                        onClick={statusManageHandler}
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked
                      />
                      <span className="py-0 px-1 bg-success rounded text-white">
                        Active
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className=" d-flex justify-content-end">
                    <button
                      type="submit"
                      id="submit"
                      name="submit"
                      className="btn btn-primary mt-2"
                      disabled={!formIsValid}
                    >
                      Add Manager
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default BdmanagerForm;
