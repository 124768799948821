import "./NavBar.css";
import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../store/authContext";
import swal from "sweetalert";
import admin from "../../assets/default.png";

const Navbar = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const logoutHandler = () => {
    swal({
      title: "Log out of BD Dashboard?",
      // text: "Log out of BD Dashboard?",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        authCtx.logout();
        history.push("/");
      }
    });
  };
  const userName = authCtx.userData;

  const managerHandler = () => {
    history.push("/BDmanagers");
  };
  const profileHandler = () => {
    history.push("/profile");
  };

  return (
    <nav className="navbar navbar-expand-lg mar navbar-light bc show  sticky-top">
      <div className="container-fluid ">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse d-flex justify-content-end"
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav ">
            <li className="dropdown">
              <a
                className="nav-link dropdown-toggle text-dark  h5 "
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={admin}
                  alt=""
                  className="me-1 img-fluid rounded-circle shadow-sm"
                  width="40"
                  height="50"
                />
                <span className="heading-color">{userName}</span>
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <button
                    onClick={profileHandler}
                    className="dropdown-item me-2"
                  >
                    <i className="fa fa-user me-2"></i> Profile
                  </button>
                </li>
                <li>
                  <button
                    onClick={managerHandler}
                    className="dropdown-item border-bottom"
                  >
                    <i className="fa fa-cog me-2"></i> Managers
                  </button>
                </li>

                <li>
                  <button
                    onClick={logoutHandler}
                    className="dropdown-item me-2"
                  >
                    <i className="fa fa-sign-out me-2"></i> Sign Out
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
