import React, { useEffect, useState, useCallback, useContext } from 'react';
import Error from '../UI/Error';
import { useHistory, Link } from 'react-router-dom';
import instance from '../../config/config';
import OfToggleButton from '../UI/OfToggle';
import MaterialTable from 'material-table';
import { reverse } from 'lodash';
import OnToggleButton from '../UI/OnToggle';
import AuthContext from '../store/authContext';
import swal from 'sweetalert';

import './Table.css';
const Table = () => {
  const [loadedHospitals, setHospitalData] = useState([]);
  const [error, setError] = useState(null);

  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const fetchSeHospitals = useCallback(async () => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get('/hospitals', { headers: headers })
      .then((response) => {
        console.log({ response });

        setHospitalData(response.data.data);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          authCtx.logout();
        }
        setError(err.message);
      });
  }, []);

  useEffect(() => {
    fetchSeHospitals();
  }, [fetchSeHospitals]);

  const profileHandler = (id) => {
    history.push({ pathname: '/hospital/' + id });
  };

  const profileEditHandler = (id) => {
    history.push('/hospitals/' + id);
  };

  const statusChangeHandler = (status, hospitalid) => {
    const hospital = {
      active: !status,
      _id: hospitalid,
    };
    //  setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };

    instance
      .patch('/hospitals/' + hospitalid, { hospital }, { headers: headers })
      .then((response) => {
        // history.push("/hospital/" + hospitalid);
        fetchSeHospitals();
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const suspendHospital = (hospitalId) => {
    swal({
      title: 'Suspend Hospital',
      text: `Are you sure you want suspend this hospital?`,
      buttons: true,
      dangerMode: true,
    }).then((willSuspend) => {
      if (willSuspend) {
        let url = `/bd/hospitals/${hospitalId}/suspend`;

        //  setError(null);
        let headers = {
          'x-access-token': authCtx.token,
          'Content-Type': 'application/json',
        };

        instance
          .post(url, {}, { headers: headers })
          .then((response) => {
            fetchSeHospitals();
          })
          .catch((err) => {
            setError(err.message);
          });
      }
    });
  };

  const activateHospital = (hospitalId) => {
    swal({
      title: 'Activate Hospital',
      text: `Are you sure you want activate this hospital?`,
      buttons: true,
      dangerMode: true,
    }).then((willSuspend) => {
      if (willSuspend) {
        let url = `/bd/hospitals/${hospitalId}/activate`;

        //  setError(null);
        let headers = {
          'x-access-token': authCtx.token,
          'Content-Type': 'application/json',
        };

        instance
          .post(url, {}, { headers: headers })
          .then((response) => {
            fetchSeHospitals();
          })
          .catch((err) => {
            setError(err.message);
          });
      }
    });
  };

  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Country',
      field: 'country',
    },
    {
      title: 'State',
      field: 'state',
    },
    {
      title: 'City',
      field: 'city',
    },

    {
      title: 'Registration Date',
      field: 'createdAt',
      render: (rowData) => {
        // Calculate years completed
        const registrationDate = new Date(rowData.createdAt);
        const currentDate = new Date();
        const yearsCompleted =
          currentDate.getFullYear() - registrationDate.getFullYear();

        // Check if it's a completed year
        const isCompletedYear =
          currentDate.getMonth() > registrationDate.getMonth() ||
          (currentDate.getMonth() === registrationDate.getMonth() &&
            currentDate.getDate() >= registrationDate.getDate());

        const totalYears = isCompletedYear
          ? yearsCompleted
          : yearsCompleted - 1;

        // If less than a year, calculate completed days
        let totalDays = 0;
        if (totalYears === 0) {
          const diffInMilliseconds = currentDate - registrationDate;
          totalDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24)); // Convert ms to days
        }

        // Render the badge
        return (
          <div>
            <span>{new Date(rowData?.createdAt)?.toDateString()}</span>
            <span className="badge bg-primary">
              {totalYears > 0
                ? `${totalYears} Year${totalYears > 1 ? 's' : ''} Completed`
                : `${totalDays} Day${totalDays > 1 ? 's' : ''} Completed`}
            </span>
          </div>
        );
      },
    },

    {
      title: 'Requests', // New Column for Requests
      field: 'request',
      // Add custom width using cellStyle and headerStyle
      cellStyle: {
        minWidth: '300px', // Minimum width for the cell
        maxWidth: '500px', // Maximum width for the cell
      },
      headerStyle: {
        minWidth: '300px', // Minimum width for the header
        maxWidth: '500px', // Maximum width for the header
      },
      render: (rowData) => (
        <div>
          {/* Transfer Request */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            <div>
              <span>Transfer:</span>{' '}
              <span>{rowData.transferRequest ? 'YES' : 'No req'}</span>
            </div>
            <div style={{ fontSize: '12px', color: 'gray', marginLeft: 6 }}>
              {rowData.transferRequestDate && (
                <span>
                  {new Date(rowData?.transferRequestDate).toLocaleDateString()}
                </span>
              )}
              {rowData?.transferRequestStatus && (
                <span> {rowData?.transferRequestStatus}</span>
              )}
            </div>
          </div>
          {/* Suspend Request */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            <div>
              <span>Suspend:</span>{' '}
              <span>{rowData.suspendRequest ? 'YES' : 'No req'}</span>
            </div>
            <div style={{ fontSize: '12px', color: 'gray', marginLeft: 6 }}>
              {rowData.suspendRequestDate && (
                <span>
                  {new Date(rowData?.suspendRequestDate).toLocaleDateString()}
                </span>
              )}
              {rowData?.suspendRequestStatus && (
                <span> {rowData?.suspendRequestStatus}</span>
              )}
            </div>
          </div>

          {/* Delete Request */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            <div>
              <span>Delete:</span>{' '}
              <span>{rowData.deleteRequest ? 'YES' : 'No req'}</span>
            </div>
            <div style={{ fontSize: '12px', color: 'gray' }}>
              {rowData.deleteRequestDate && (
                <span>
                  {new Date(rowData.deleteRequestDate).toLocaleDateString()}
                </span>
              )}
            </div>
            <div>
              {rowData?.deleteRequestStatus && (
                <span> {rowData?.deleteRequestStatus}</span>
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Status',
      field: 'suspendedBybd',
      type: 'boolean',
      render: (rowData) => (
        <div>
          {!rowData.suspendedBybd && (
            <span className=" badge badge-success">active</span>
          )}
          {rowData.suspendedBybd && (
            <span className="badge badge-danger">Inactive</span>
          )}
        </div>
      ),
    },

    {
      title: 'Actions',
      render: (rowData) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              rowData.suspendedBybd
                ? activateHospital(rowData?._id)
                : suspendHospital(rowData?._id);
            }}
            className={`btn ${
              rowData.suspendedBybd ? 'btn-success' : 'btn-danger'
            }`}
          >
            {rowData.suspendedBybd ? 'Activate' : 'Suspend'}
          </button>
          <button disabled={true} className="btn btn-danger">
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    // <div className="row">
    <div className="col-md-6 col-xl-12 px-3 ">
      <MaterialTable
        title="Hospitals"
        style={{
          marginTop: '2rem',
          // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
          borderRadius: '10px',
        }}
        data={reverse(loadedHospitals)}
        columns={columns}
        onRowClick={(event, r) => {
          event.stopPropagation();
          history.push(`hospital/${r?._id}`);
        }}
        options={{
          headerStyle: { color: 'black', fontSize: '15px' },
          // exportButton: true,
          // grouping: true,
          pageSize: 4,
          //rowStyle: { backgroundColor: 'green'},
          rowStyle: (rowData) => {
            if (!rowData.active) {
              return { backgroundColor: '#BFBFBF' };
            }

            return {};
          },
        }}
      ></MaterialTable>
    </div>
    // </div>
  );
};

export default Table;
