import "./HospitalEdit.css";
import Input from "../UI/input";
import React, { useEffect, useState, useCallback, useContext } from "react";
import LoadingSpinner from "../UI/LoadsingSpinner";
import Error from "../UI/Error";
import instance from "../../config/config";
import useInput from "../hooks/use-Input";
import AuthContext from "../store/authContext";
import Navbar from "../Layouts/Navbar";

const isNotEmpty = (value) => {
  return value.trim() !== "";
};

const isEmail = (value) => {
  var reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (reg.test(value) === false) {
    return false;
  } else {
    return true;
  }
};

const HospitalEdit = (props) => {
  const [loadedsingleHospital, setHospitalData] = useState([]);
  const [error, setError] = useState(null);
  const [loadingSpinner, setloadingSpinner] = useState(false);
  const authCtx = useContext(AuthContext);

  const {
    value: enteredName,
    hasError: nameInputHasError,
    valueCHangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    isValid: enteredNameIsValid,
    reset: resetNameInput,
  } = useInput(isNotEmpty);

  const {
    value: enteredEmail,
    hasError: emailInputHasError,
    valueCHangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    isValid: enteredEmailIsValid,
    reset: resetEmailInput,
  } = useInput(isEmail);

  // const [enteredName, setEnteredName] = useState("");

  // const [enteredEmail, setEnteredEmail] = useState("");

  const id = props.id;
  //   const enteredNameIsValid = isNotEmpty(enteredName);

  //   const nameInputIsInvalid = enteredNameIsValid;

  //   const enteredEmailIsValid = isEmail(enteredEmail);
  //   const emailInputIsInvalid = enteredEmailIsValid;

  //   const nameChangeHandler = (event) => {
  //     setEnteredName(event.target.value);
  //   };

  //   const emailChangeHandler = (event) => {
  //     setEnteredEmail(event.target.value);
  //   };
  //  const nameChangeHandler = (event) => {
  //    setEnteredName(event.target.value);
  //  };

  //  const emailChangeHandler = (event) => {
  //    setEnteredEmail(event.target.value);
  //  };

  const fetchSingleHospital = useCallback(() => {
    setError(null);
    setloadingSpinner(true);
    let headers = {
      "x-access-token": authCtx.token,
      "Content-Type": "application/json",
    };
    instance
      .get("/hospitals/" + id, { headers: headers })
      .then((response) => {
        setloadingSpinner(false);
        return response.data.data;
      })
      .then((data) => {
        const singleHospital = {
          _id: data._id,
          name: data.name,
          email: data.email,
          mobile: data.mobile,
          active: data.active,
          city: data.city,
          pincode: data.pincode,
          rating: data.rating,
          address: data.address,
        };

        setHospitalData(singleHospital);
      })
      .catch((err) => {
        setloadingSpinner(false);
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.response.data.message || "Something went wrong!");
      });
  }, []);

  useEffect(() => {
    fetchSingleHospital(id);
  }, [fetchSingleHospital]);

  if (loadingSpinner) {
    return <LoadingSpinner />;
  }

  const submitHandler = (event) => {
    event.preventDefault();

    if (!enteredNameIsValid && !enteredEmailIsValid) {
      return;
    }

    const id = loadedsingleHospital._id;

    const hospital = {
      name: enteredName,
      email: enteredEmail,
      // mobile: enteredMobile,
      // pincode: enteredPincode,
      // city: enteredCity,
      // address: enteredAddress,
      // rating: enteredRating,
    };

    props.updateHospital(hospital, id);
    // setEnteredName("");
    // setEnteredNameTouched(false);
    // setEnteredEmail("");
    // setEnteredEmailTouched(false);
  };

  return (
    <div>
      <div className="container">
        <div className="row gutters">
          {props.error && <Error err={props.error} />}
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div className="card h-100">
              <div className="card-body">
                <div className="account-settings">
                  <div className="user-profile">
                    <div className="user-avatar">
                      <img
                        src="https://images.unsplash.com/photo-1587351021759-3e566b6af7cc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80"
                        alt="Maxwell Admin"
                      />
                    </div>
                    <h5 className="user-name">{loadedsingleHospital.name}</h5>
                    <h6 className="user-email">{loadedsingleHospital.email}</h6>
                  </div>
                  <div className="about">
                    <h5>Address</h5>
                    <p>{loadedsingleHospital.address}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form
            onSubmit={submitHandler}
            className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12"
          >
            <div className="card h-100">
              <div className="card-body">
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 className="mb-2 text-primary">Personal Details</h6>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="hidden" value={loadedsingleHospital._id} />
                      <Input
                        label="Name"
                        onChange={nameChangeHandler}
                        onBlur={nameBlurHandler}
                        input={{
                          name: "name",
                          type: "text",
                          defaultValue: loadedsingleHospital.name,
                        }}
                      />

                      {nameInputHasError && (
                        <p className="text-danger"> Name Cannot Be Empty</p>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <Input
                        label="Email"
                        onChange={emailChangeHandler}
                        onBlur={emailBlurHandler}
                        input={{
                          type: "email",
                          name: "email",
                          defaultValue: loadedsingleHospital.email,
                        }}
                      />

                      {emailInputHasError && (
                        <p className="text-danger"> Invalid Email</p>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <Input
                        label="Mobile"
                        input={{
                          type: "mobile",
                          defaultValue: loadedsingleHospital.mobile,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <Input
                        label="Image"
                        input={{
                          type: "file",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 className="mt-3 mb-2 text-primary">Address</h6>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <Input
                        label="Pincode"
                        input={{
                          type: "pincode",
                          defaultValue: loadedsingleHospital.pincode,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <Input
                        label="city"
                        input={{
                          type: "text",
                          defaultValue: loadedsingleHospital.city,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <Input
                        label="Address"
                        input={{
                          type: "text",
                          defaultValue: loadedsingleHospital.address,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <Input
                        label="Rating"
                        input={{
                          type: "number",

                          defaultValue: loadedsingleHospital.rating,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="text-right">
                      <button
                        type="submit"
                        id="submit"
                        name="submit"
                        className="button-3 mt-4"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HospitalEdit;
