import React, { useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import UpdateManagerForm from "./UpdateManagerForm";
import instance from "../../config/config";
import AuthContext from "../store/authContext";

import Error from "../UI/Error";
import Navbar from "../Layouts/Navbar";

const UpdateManager = () => {
  const [error, setError] = useState(null);
  const params = useParams();
  var managerId = params.managerId;

  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const updateManagerHandler = async (managerData, managerId) => {
    let headers = {
      "x-access-token": authCtx.token,
      "Content-Type": "application/json",
    };
    instance
      .patch(
        "/bd/bdManagers/" + managerId,
        { managerData },
        { headers: headers }
      )
      .then((response) => {
        history.push("/BDmanagers");
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.response.data.message || "Something went wrong!");
      });
  };

  return (
    <>
      <Navbar />
      <UpdateManagerForm
        id={managerId}
        updateManager={updateManagerHandler}
        error={error}
      />
    </>
  );
};

export default UpdateManager;
