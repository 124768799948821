const OfToggleButton = (props) => {
  return (
    <div className="form-check form-switch">
      <input
        onClick={props.onClick}
        className="form-check-input"
        type="checkbox"
        id="flexSwitchCheckDefault"
      />
    </div>
  );
};

export default OfToggleButton;
