import React, { useState, useContext, useCallback, useEffect } from "react";
import instance from "../../config/config";
import AuthContext from "../store/authContext";
import "./AdminProfile1.css";
import { capitalize } from "lodash";
import Navbar from "../Layouts/Navbar";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../UI/LoadsingSpinner";
import Error from "../UI/Error";
import pic from "../../assets/default.png";
function AdminProfile() {
  const [manager, setManager] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState("");

  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const managerId = localStorage.getItem("userId");

  const fetchManager = useCallback(() => {
    setLoading(true);
    setError(null);
    let headers = {
      "x-access-token": authCtx.token,
      "Content-Type": "application/json",
    };
    instance
      .get("/bd/bdManagers/" + managerId, { headers: headers })
      .then((response) => {
        localStorage.setItem("userMobile", response.data.data.mobile);
        setManager(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log({ err });
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setLoading(false);
        setError(err.message);
      });
  }, []);

  const otpHandler = () => {
    history.push("/reset");
    // instance
    //   .post("/auth/login/otp", {
    //     mobile: localStorage.getItem("userMobile"),
    //     countryCode: 91,
    //     platform: "bd",
    //   })
    //   .then((response) => {
    //     console.log(response);
    //     localStorage.setItem("id", response.data.data._id);
    //     history.push("/verify");
    //   })
    //   .catch((err) => {
    //     console.log({ err });
    //     setError(err.response.data.message || "Something went wrong!");
    //   });
  };

  useEffect(() => {
    fetchManager();
  }, [fetchManager]);
  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <Navbar />
      <div className="d-flex justify-content-end mt-4 me-4">
        <button onClick={otpHandler} className="btn btn-primary">
          Password{" "}
          <i className="ms-1 fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </div>
      <div className="container d-flex justify-content-center">
        {error && <Error err={error} />}
        <div className="row">
          {loading && <LoadingSpinner />}
          <div className="col">
            <div class="c">
              <div class="imgg">
                <img src={pic} />
              </div>
              <div class="infos">
                {manager && <div class="name fw-bold">{manager.name}</div>}
                <ul class="stats">
                  {manager && (
                    <li>
                      <h2>Role</h2>
                      <h4>
                        <i className="fa fa-medkit  text-muted me-2"></i>
                        {capitalize(manager.role)}
                      </h4>
                    </li>
                  )}
                  {manager && (
                    <li>
                      <h2>Email</h2>
                      <h4>
                        <i className="fa fa-envelope me-2 text-muted"></i>
                        {capitalize(manager.email)}
                      </h4>
                    </li>
                  )}
                  {manager && (
                    <li>
                      <h2>Mobile</h2>
                      <h4>
                        <i class="fa fa-phone me-2 ms-1" aria-hidden="true"></i>
                        {capitalize(manager.mobile)}
                      </h4>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AdminProfile;
