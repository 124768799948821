import React, { useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import HospitalEdit from "./HospitalEdit";
import instance from "../../config/config";
import AuthContext from "../store/authContext";

import Error from "../UI/Error";
import Navbar from "../Layouts/Navbar";

const UpdateHospital = () => {
  const [error, setError] = useState(null);
  const params = useParams();
  var hospitalId = params.hospitalId;

  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const updateHospitalHandler = async (hospital, hospitalId) => {
    let headers = {
      "x-access-token": authCtx.token,
      "Content-Type": "application/json",
    };

    instance
      .patch("/hospitals/" + hospitalId, { hospital }, { headers: headers })
      .then((response) => {
        history.push("/hospital/" + hospitalId);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.response.data.message || "Something went wrong!");
      });
  };

  // if (error) {
  //   return <Error err={error} />;
  // }

  return (
    <>
      <Navbar />
      <HospitalEdit
        id={hospitalId}
        updateHospital={updateHospitalHandler}
        error={error}
      />
    </>
  );
};

export default UpdateHospital;
