const onToggleButton = (props) => {
  // if (props.status === true) {
  return (
    <div className="form-check form-switch">
      <input
        onClick={props.onClick}
        className="form-check-input"
        type="checkbox"
        id="flexSwitchCheckChecked"
        checked
      />
    </div>
  );
  // } else {
  //   return (
  //     <div className="form-check form-switch">
  //       <input
  //         onClick={props.onClick}
  //         className="form-check-input"
  //         type="checkbox"
  //         id="flexSwitchCheckDefault"
  //       />
  //     </div>
  //   );
  // }
};

export default onToggleButton;
