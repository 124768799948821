import "./HospitalEdit.css";
import Input from "../UI/input";
import React from "react";

import useInput from "../hooks/use-Input";
import Navbar from "../Layouts/Navbar";

const isNotEmpty = (value) => {
  return value.trim() !== "";
};

const isEmail = (value) => {
  var reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (reg.test(value) === false) {
    return false;
  } else {
    return true;
  }
};

const HospitalForm = (props) => {
  const {
    value: enteredName,
    hasError: nameInputHasError,
    valueCHangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    isValid: enteredNameIsValid,
    reset: resetNameInput,
  } = useInput(isNotEmpty);

  const {
    value: enteredEmail,
    hasError: emailInputHasError,
    valueCHangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    isValid: enteredEmailIsValid,
    reset: resetEmailInput,
  } = useInput(isEmail);

  let formIsValid = false;
  if (enteredNameIsValid && enteredEmailIsValid) {
    formIsValid = true;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    if (!enteredNameIsValid && !enteredEmailIsValid) {
      return;
    }

    const hospital = {
      name: enteredName,
      email: enteredEmail,
      // mobile: enteredMobile,
      // pincode: enteredPincode,
      // city: enteredCity,
      // address: enteredAddress,
      // rating: enteredRating,
    };

    props.addHospital(hospital);
  };
  const style = (errorState) => {
    if (errorState) {
      return "form-group invalid";
    } else {
      return "form-group";
    }
  };

  return (
    <>
      <Navbar />
      <form
        onSubmit={submitHandler}
        className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
      >
        <h2 className="card-header text-center text-light bg-dark rounded-top">
          Add Hospital
        </h2>
        <div className="card h-100">
          <div className="card-body">
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 className="mb-2 text-primary">Personal Details</h6>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className={style(nameInputHasError)}>
                  <Input
                    onChange={nameChangeHandler}
                    onBlur={nameBlurHandler}
                    label="Name"
                    input={{
                      type: "text",
                      value: enteredName,
                    }}
                  />
                  {nameInputHasError && (
                    <p className="text-danger"> Name Cannot Be Empty</p>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className={style(emailInputHasError)}>
                  <Input
                    onChange={emailChangeHandler}
                    onBlur={emailBlurHandler}
                    label="Email"
                    input={{
                      type: "text",
                    }}
                  />
                  {emailInputHasError && (
                    <p className="text-danger"> Email is Not Valid</p>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <Input
                    label="Mobile"
                    input={{
                      type: "mobile",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <Input
                    label="Image"
                    input={{
                      type: "file",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 className="mt-3 mb-2 text-primary">Address</h6>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <Input
                    label="Pincode"
                    input={{
                      type: "pincode",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <Input
                    label="city"
                    input={{
                      type: "text",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <Input
                    label="Address"
                    input={{
                      type: "text",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <Input
                    label="Rating"
                    input={{
                      type: "number",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="text-right">
                  <button
                    type="submit"
                    id="submit"
                    name="submit"
                    className="button-3 mt-4"
                    disabled={!formIsValid}
                  >
                    Add Hospital
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default HospitalForm;
