const Error = (props) => {
  return (
    <div className="alert alert-danger d-flex align-items-center" role="alert">
      <h3 className="text-center">
        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {props.err}
      </h3>
    </div>
  );
};

export default Error;
