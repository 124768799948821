import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import './card.css';
import Table from '../Table/Table';
import LoadingSpinner from '../UI/LoadsingSpinner';
import Error from '../UI/Error';
import instance from '../../config/config';
import AuthContext from '../store/authContext';
import Navbar from '../Layouts/Navbar';
import Graph from '../chart/Graph';

const Card = () => {
  const [HospitalData, setHospitalData] = useState([]);
  const [error, setError] = useState(null);
  const [loadingSpinner, setloadingSpinner] = useState(false);
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const fetchHospitalsData = useCallback(() => {
    setError(null);
    setloadingSpinner(true);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get('/bd/dashboard', { headers: headers })
      .then((response) => {
        setloadingSpinner(false);
        return response.data.data;
      })
      .then((data) => {
        const hospital = {
          hospitalCount: data.hospitalCount,
          adminCount: data.adminCount,
          doctorsCount: data.doctorsCount,
          patientCount: data.patientCount,
        };
        setHospitalData(hospital);
      })
      .catch((err) => {
        setloadingSpinner(false);
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.message || 'Something went wrong!');
      });
  }, []);

  useEffect(() => {
    fetchHospitalsData();
  }, [fetchHospitalsData]);

  if (loadingSpinner) {
    return (
      <div className="centered">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadingSpinner && error) {
    return <Error err={error} />;
  }
  let data = [
    { name: 'Hospitals', value: HospitalData.hospitalCount },
    { name: 'Patients', value: HospitalData.patientCount },
    { name: 'Admins', value: HospitalData.adminCount },
    { name: 'Doctors', value: HospitalData.doctorsCount },
  ];

  const graphData = {
    labels: data.map((d) => d.name),
    datasets: [
      {
        label: 'My First Dataset',
        data: data.map((d) => d.value),
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)',
        ],
        hoverOffset: 4,
      },
    ],
  };

  function transparentize(r, g, b, alpha) {
    const a = (1 - alpha) * 255;
    const calc = (x) => Math.round((x - a) / alpha);

    return `rgba(${calc(r)}, ${calc(g)}, ${calc(b)}, ${alpha})`;
  }

  const DATA_COUNT = 7;
  const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };
  function number(config) {
    var cfg = config || {};
    var min = cfg.min || 0;
    var max = cfg.max || 1;
    var from = cfg.from || [];
    var count = cfg.count || 8;
    var decimals = cfg.decimals || 8;
    var continuity = cfg.continuity || 1;
    var dfactor = Math.pow(10, decimals) || 0;
    var data = [];
    var i, value;

    for (i = 0; i < count; ++i) {
      value = (from[i] || 0) + Math.random(min, max);
      if (Math.random() <= continuity) {
        data.push(Math.round(dfactor * value) / dfactor);
      } else {
        data.push(null);
      }
    }

    return data;
  }

  const labels = ['red', 'blue', 'green'];
  const doughnutOptions = {
    responsive: true,
    aspectRatio: 2,
    // weight: 00,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Hospital details',
      },
    },
  };

  const lineData = {
    labels: labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: number({ count: 40, min: -100, max: 100 }),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: transparentize(255, 99, 132, 0.5),
      },
      {
        label: 'Dataset 2',
        data: number({ count: 30, min: -100, max: 100 }),
        borderColor: 'rgb(54, 162, 135)',
        backgroundColor: transparentize(54, 162, 135, 0.5),
      },
    ],
  };
  return (
    <React.Fragment>
      <Navbar />
      {!error && (
        <div>
          <div className="row py-5 px-4">
            <div className="col-xl-3 col-md-6">
              <div className="card border-start border-3 border-primary   py-2 ">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-1">
                      <div className="text-xs font-weight-bold text-primary text-uppercase ">
                        Hospitals
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {HospitalData.hospitalCount}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fa fa-hospital-o fa-2x text-muted"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="card  border-start border-3 border-success   py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-1">
                      <div className="text-xs font-weight-bold text-success text-uppercase ">
                        Doctors
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {HospitalData.doctorsCount}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fa fa-medkit fa-2x text-muted"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="card  border-start border-3 border-info   py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-1">
                      <div className="text-xs font-weight-bold text-info text-uppercase ">
                        Admins
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {HospitalData.adminCount}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fa fa-user fa-2x text-muted"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="card  border-start border-3 border-warning   py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-1">
                      <div className="text-xs font-weight-bold text-warning text-uppercase ">
                        Patients
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {HospitalData.patientCount}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fa fa-plus-square fa-2x text-muted"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row py-3 px-4">
            <div className="col-xl-6 col-md-4">
              <div className="card shadow-none">
                <div className="card-body">
                  {/* <ResponsiveContainer
                    width={400}
                    height={400}
                    className="text-center"
                  >
                    <PieChart width={600} height={400}>
                      <Legend
                        layout="vertical"
                        verticalAlign="top"
                        align="top"
                      />
                      <Pie
                        data={data}
                        cx={190}
                        // cy={200}
                        labelLine={true}
                        //   label={renderCustomizedLabel}
                        outerRadius={100}
                        label="name"
                        //   nameKey="name"
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {data.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer> */}
                  <Graph
                    className="col-md-12"
                    maxHeight="500px"
                    type="doughnut"
                    data={graphData}
                    options={doughnutOptions}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-4">
              <div className="card shadow-none">
                <div className="card-body">
                  <Graph
                    className="col-md-12"
                    maxHeight="500px"
                    type="line"
                    data={lineData}
                  />
                  {/* <ResponsiveContainer
                    // width={800}
                    height={400}
                    // className="text-center"
                  >
                    <BarChart
                      width={500}
                      height={300}
                      data={barData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="pv"
                        fill="#8884d8"
                        background={{ fill: '#eee' }}
                      />
                      <Bar dataKey="uv" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer> */}
                  {/* <Graph
                    className="col-md-12 h-75"
                    maxHeight="500px"
                    type="line"
                    data={lineData}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          {/* <Table /> */}
        </div>
      )}
    </React.Fragment>
  );
};

export default Card;
