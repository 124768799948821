import React, { useState, Fragment, useContext } from "react";
import AuthContext from "../store/authContext";
import { useHistory } from "react-router-dom";
import instance from "../../config/config";

import HospitalForm from "./HospitalForm";
import { addHospital } from "../lib/api";
import useHttp from "../hooks/useHttp";
import Error from "../UI/Error";
import Navbar from "../Layouts/Navbar";
const AddHospital = () => {
  const [error, setError] = useState("");
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const hospitalAddHandler = (hospital) => {
    let headers = {
      "x-access-token": token,
      "Content-Type": "application/json",
    };

    instance
      .post("/hospitals", { hospital }, { headers: headers })
      .then((response) => {
        history.push("/hospitals");
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.response.data.message || "Something went wrong!");
      });
  };

  return (
    <Fragment>
      {error && <Error err={error} />}
      <HospitalForm addHospital={hospitalAddHandler} />
    </Fragment>
  );
};

export default AddHospital;
