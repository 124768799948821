import React, { useContext, useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import AuthContext from '../store/authContext';
import instance from '../../config/config';
import OnToggleButton from '../UI/OnToggle';
import Navbar from '../Layouts/Navbar';
import MaterialTable from 'material-table';
import OfToggleButton from '../UI/OfToggle';
import { reverse } from 'lodash';

const Managers = () => {
  const [loadedManagers, setManagers] = useState([]);
  const [error, setError] = useState(null);

  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const addManagerHandler = () => {
    history.push('addmanager');
  };
  const managerEditHandler = (id) => {
    history.push({ pathname: '/updatemanager/' + id });
  };

  const fetchBdmanagers = useCallback(async () => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get('/bd/bdmanagers', { headers: headers })
      .then((response) => {
        const updatedData = response.data.data.map((data) => {
          return data;
        });
        setManagers(updatedData);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.message);
      });
  }, []);

  useEffect(() => {
    fetchBdmanagers();
  }, [fetchBdmanagers]);

  const statusChangeHandler = (status, managerId) => {
    const managerData = {
      active: !status,
      _id: managerId,
    };
    //  setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };

    instance
      .patch(
        '/bd/bdManagers/' + managerId,
        { managerData },
        { headers: headers }
      )
      .then((response) => {
        fetchBdmanagers();
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.response.data.message || 'Something went wrong!');
      });
  };

  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'Mobile',
      field: 'mobile',
    },
    {
      title: 'Status',
      field: 'active',
      type: 'boolean',
      render: (rowData) => (
        <div>
          {rowData.active && (
            <span className="badge badge-success">active</span>
          )}
          {!rowData.active && (
            <span className="badge badge-danger">Inactive</span>
          )}

          {rowData.active && (
            <OnToggleButton
              onClick={statusChangeHandler.bind(
                this,
                rowData.active,
                rowData._id
              )}
            />
          )}
          {!rowData.active && (
            <OfToggleButton
              onClick={statusChangeHandler.bind(
                this,
                rowData.active,
                rowData._id
              )}
            />
          )}
        </div>
      ),
    },

    {
      title: 'Actions',
      render: (rowData) => (
        <div>
          <Link
            to={`/updatemanager/${rowData._id}`}
            className="btn btn-success"
          >
            <i class="fa fa-edit"></i>
          </Link>
        </div>
      ),
    },
  ];

  // let content;
  // if (loadedManagers) {
  //   content = loadedManagers.map((managers) => {
  //     return (
  //       <tr key={managers._id}>
  //         <td style={{ cursor: "pointer" }}>{managers.name}</td>
  //         <td style={{ cursor: "pointer" }}>{managers.email}</td>
  //         <td style={{ cursor: "pointer" }}>{managers.mobile}</td>
  //         {managers.active === true && (
  //           <td key={managers._id} style={{ cursor: "pointer" }}>
  //             <span className="py-0 px-1 bg-success rounded text-white">
  //               Active
  //             </span>
  //             <OnToggleButton
  //               status={managers.active}
  //               onClick={statusChangeHandler.bind(
  //                 this,
  //                 managers.active,
  //                 managers._id
  //               )}
  //             />
  //           </td>
  //         )}
  //         {managers.active === false && (
  //           <td key={managers._id} style={{ cursor: "pointer" }}>
  //             <span className="py-0 px-1 bg-danger rounded text-white">
  //               Inactive
  //             </span>
  //             <OnToggleButton
  //               status={managers.active}
  //               onClick={statusChangeHandler.bind(
  //                 this,
  //                 managers.active,
  //                 managers._id
  //               )}
  //             />
  //           </td>
  //         )}
  //         <td>
  //           <button
  //             onClick={managerEditHandler.bind(this, managers._id)}
  //             className="btn btn-primary"
  //           >
  //             Edit
  //           </button>
  //         </td>
  //       </tr>
  //     );
  //   });
  // }

  return (
    <>
      <Navbar />
      <button
        className="button-3 mt-3"
        style={{ marginLeft: '53rem' }}
        onClick={addManagerHandler}
      >
        Add Manager
      </button>
      <div className="row p-4">
        <MaterialTable
          title="Managers"
          style={{
            marginTop: '2rem',
            // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
            borderRadius: '10px',
          }}
          data={reverse(loadedManagers)}
          columns={columns}
          options={{
            exportButton: true,
            grouping: true,
            //rowStyle: { backgroundColor: 'green'},
            rowStyle: (rowData) => {
              if (!rowData.active) {
                return { backgroundColor: '#BFBFBF' };
              }

              return {};
            },
          }}
        ></MaterialTable>
      </div>
    </>
  );
};
export default Managers;
