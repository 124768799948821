import MainNavigation from "./MainNavigation";
import { Fragment } from "react";
import Navbar from "./Navbar";

const Layout = (props) => {
  return (
    <Fragment>
      {/* <Navbar /> */}
      <MainNavigation />
      <div className="content-container">
        <div className="container-fluid">{props.children}</div>
      </div>
    </Fragment>
  );
};

export default Layout;
