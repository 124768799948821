import React, { useState, useEffect, useContext, useCallback } from 'react';
import instance from '../../config/config';
import { useHistory, Link } from 'react-router-dom';
import AuthContext from '../store/authContext';
import Error from '../UI/Error';
import Navbar from '../Layouts/Navbar';
import Select from 'react-select';
import Pricing from '../pricing/Pricing';
import Input from '../UI/input';
import useInput from '../hooks/use-Input';
import LoadingSpinner from '../UI/LoadsingSpinner';
import MaterialTable from 'material-table';
const isNotEmpty = (value) => {
  return value.trim() !== '';
};

const Subscription = () => {
  const authCtx = useContext(AuthContext);
  const [plans, setPlans] = useState([]);
  const [selectValue, setSelectValue] = useState([]);
  const [loadingSpinner, setloadingSpinner] = useState(null);
  const [selectError, setSelectError] = useState(false);

  const [features, setFeatures] = useState([]);
  const selectOptions = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];
  const fetchSubscriptions = useCallback(async (hospitalId) => {
    // setError(null);
    setloadingSpinner(true);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get(`/subscriptions`, {
        headers: headers,
      })
      .then((response) => {
        console.log('runn');
        setPlans(response.data.data.plans);
        setFeatures(response.data.data.features);
        setloadingSpinner(false);
      })
      .catch((err) => {
        // if (err?.response?.status == 401) {
        //   authCtx.logout();
        // }
        // setError(err.message);
        setloadingSpinner(false);
      });
  }, []);

  const {
    value: enteredName,
    hasError: nameInputHasError,
    valueCHangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    isValid: enteredNameIsValid,
    reset: resetNameInput,
  } = useInput(isNotEmpty);

  const {
    value: enteredPrice,
    hasError: priceInputHasError,
    valueCHangeHandler: priceChangeHandler,
    inputBlurHandler: priceBlurHandler,
    isValid: enteredPriceIsValid,
    reset: priceresetNameInput,
  } = useInput(isNotEmpty);

  const {
    value: enteredPlanName,
    hasError: plannameInputHasError,
    valueCHangeHandler: plannameChangeHandler,
    inputBlurHandler: plannameBlurHandler,
    isValid: planenteredNameIsValid,
    reset: planresetNameInput,
  } = useInput(isNotEmpty);

  const {
    value: enteredSubtitle,
    hasError: subtitleInputHasError,
    valueCHangeHandler: subtitleChangeHandler,
    inputBlurHandler: subtitleBlurHandler,
    isValid: subtitleNameIsValid,
    reset: subtitleresetNameInput,
  } = useInput(isNotEmpty);

  const submitHandler = (event) => {
    event.preventDefault();

    if (selectValue.length <= 0) {
      setSelectError(true);
      return;
    }
    let formData = {
      subscriptionDetails: {
        feature: enteredName,
        plans: selectValue.map((val) => val.value),
      },
    };
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .post('/subscriptions', formData, { headers: headers })
      .then((response) => {
        resetNameInput();
        setSelectValue([]);
        fetchSubscriptions();
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        // setError(err.response.data.message || 'Something went wrong!');
      });
  };

  const planSubmitHandler = (event) => {
    event.preventDefault();
    if (
      !enteredPriceIsValid &&
      !subtitleNameIsValid &&
      !planenteredNameIsValid
    ) {
      return;
    }
    console.log(enteredPrice);
    let formData = {
      subscriptionDetails: {
        title: enteredPlanName,
        price: enteredPrice,
        subtitle: enteredSubtitle,
      },
    };
    priceresetNameInput();
    planresetNameInput();
    subtitleresetNameInput();
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .post('/subscriptions/plans', formData, { headers: headers })
      .then((response) => {
        fetchSubscriptions();
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        // setError(err.response.data.message || 'Something went wrong!');
      });
  };

  const planEditHandler = (value, rowdata) => {
    if (!rowdata.tableData.editCellList > 0) {
      return;
    }
    let editedCell = rowdata.tableData.editCellList[0];
    let updateObj = {};
    if (editedCell.field === 'title') {
      updateObj = {
        title: value,
      };
    } else if (editedCell.field === 'price') {
      updateObj = {
        price: value,
      };
    } else if (editedCell.field === 'subtitle') {
      updateObj = {
        subtitle: value,
      };
    } else {
      return;
    }

    let formData = {
      subscriptionDetails: {
        ...updateObj,
      },
    };

    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .patch(`/subscriptions/plans/${rowdata._id}`, formData, {
        headers: headers,
      })
      .then((response) => {})
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        // setError(err.response.data.message || 'Something went wrong!');
      });
  };

  const planDeleteHandler = (rowdata) => {
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .delete(`/subscriptions/plans/${rowdata._id}`, {
        headers: headers,
      })
      .then((response) => {
        fetchSubscriptions();
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        // setError(err.response.data.message || 'Something went wrong!');
      });
  };
  const featureDeleteHandler = (rowdata) => {
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .delete(`/subscriptions/${rowdata._id}`, {
        headers: headers,
      })
      .then((response) => {
        fetchSubscriptions();
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        // setError(err.response.data.message || 'Something went wrong!');
      });
  };

  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);
  // if (loadingSpinner) {
  //   return (
  //     <div className="centered">
  //       <LoadingSpinner />
  //     </div>
  //   );
  // }
  const columns = [
    {
      title: 'Name',
      field: 'title',
    },
    {
      title: 'Price',
      field: 'price',
    },

    {
      title: 'Descripton',
      field: 'subtitle',
    },
  ];
  let subsColumn = [
    {
      title: 'Feature',
      field: 'feature',
    },
  ];
  {
    plans &&
      plans.forEach((plan) => {
        subsColumn.push({
          title: plan.title,
          field: '',
        });
        features.forEach((feature) => {
          subsColumn.forEach((col) => {
            if (col.title === plan.title) {
              col.render = (rowData) => (
                <div>
                  {rowData.plans.includes(plan.title) ? (
                    <i className="fa fa-check"></i>
                  ) : (
                    <i class="fa fa-close"></i>
                  )}
                </div>
              );
            }
          });
        });
      });
  }
  return (
    <>
      <Navbar />
      <div className="row px-4 py-4">
        <h2 className="mb-4 px-4 me-4">Subscription Details</h2>
        <div class="card">
          <div class="card-body">
            <h4 className="mt-2">Plans</h4>
            <form onSubmit={planSubmitHandler} action="">
              <div className="row">
                <p>Add one plan at a time</p>
                <div className="col-xl-3 col-md-2">
                  {/* <p>Add one plan at a time</p> */}
                  <Input
                    onChange={plannameChangeHandler}
                    onBlur={plannameBlurHandler}
                    input={{
                      type: 'Title',
                      placeholder: 'Plan',
                      value: enteredPlanName,
                    }}
                  />
                  {plannameInputHasError && (
                    <p className="text-danger"> Plan name cannot be empty</p>
                  )}
                </div>
                <div className="col-xl-3 col-md-2">
                  <Input
                    onChange={priceChangeHandler}
                    onBlur={priceBlurHandler}
                    // label="Feature"

                    input={{
                      type: 'Number',
                      placeholder: 'Price',
                      value: enteredPrice,
                    }}
                  />
                  {priceInputHasError && (
                    <p className="text-danger"> Price cannot be empty</p>
                  )}
                </div>
                <div className="col-xl-4 col-md-2">
                  <Input
                    onChange={subtitleChangeHandler}
                    onBlur={subtitleBlurHandler}
                    // label="Feature"

                    input={{
                      type: 'text',
                      placeholder: 'Subtitle',
                      value: enteredSubtitle,
                    }}
                  />
                  {subtitleInputHasError && (
                    <p className="text-danger"> Subtitle cannot be empty</p>
                  )}
                </div>

                <div className="col-xl-2 col-md-2 mt-4">
                  <button type="submit" className="button-3 ms-5">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    Add
                    {/* <i
                      className=" ms-1 fa fa-chevron-right"
                      aria-hidden="true"
                    ></i> */}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-4 col-xl-12">
          <MaterialTable
            cellEditable={{
              cellStyle: {},
              onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                return new Promise((resolve, reject) => {
                  planEditHandler(newValue, rowData);
                  fetchSubscriptions();

                  resolve();
                });
              },
            }}
            editable={{
              isEditable: true,
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    planDeleteHandler(oldData);
                    resolve();
                  }, 1000);
                }),
            }}
            title="Plans"
            style={{
              marginTop: '1rem',
              borderRadius: '10px',
              padding: '10px',
            }}
            data={plans}
            columns={columns}
            options={{
              pageSize: 4,
              headerStyle: {
                color: 'black',
                fontSize: '15px',
              },
            }}
          ></MaterialTable>
        </div>
        <div class="card mt-4">
          <div class="card-body">
            <h4 className="mt-2">Features</h4>
            <form onSubmit={submitHandler} action="">
              <div className="row mt-2">
                <div className="col-xl-5 col-md-4">
                  <p className="mt-2">Add one feature at a time</p>
                  <Select
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={selectValue || ''}
                    onChange={(value) => setSelectValue(value)}
                    options={plans.map((plan) => {
                      return { value: plan._id, label: plan.title };
                    })}
                  />
                  {selectError && <p className="text-danger">Plan required</p>}
                </div>
                <div className="col-xl-5 col-md-4 mt-2">
                  <Input
                    onChange={nameChangeHandler}
                    onBlur={nameBlurHandler}
                    // label="Feature"

                    input={{
                      type: 'text',
                      placeholder: 'Feature',
                      value: enteredName,
                    }}
                  />
                  {nameInputHasError && (
                    <p className="text-danger"> Feature cannot be empty</p>
                  )}
                </div>
                <div className="col-xl-2 col-md-2 mt-4">
                  <button type="submit" className="button-3 ms-5">
                    <i class="fa fa-plus me-2 " aria-hidden="true"></i>
                    Add
                    {/* <i
                      className=" ms-1 fa fa-chevron-right"
                      aria-hidden="true"
                    ></i> */}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="row px-4">
        <MaterialTable
          editable={{
            isEditable: true,
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  featureDeleteHandler(oldData);
                  resolve();
                }, 1000);
              }),
          }}
          title="Features"
          style={{
            marginTop: '1rem',
            borderRadius: '10px',
            padding: '10px',
          }}
          data={features}
          columns={subsColumn}
          options={{
            pageSize: 8,
            headerStyle: {
              color: 'black',
              fontSize: '15px',
            },
          }}
        ></MaterialTable>
        {/* <Pricing plans={plans} features={features} /> */}
      </div>
    </>
  );
};

export default Subscription;
