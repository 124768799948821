import React, { useContext, useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import instance from '../../config/config';
import AuthContext from '../store/authContext';
import { Link } from 'react-router-dom';
import _, { reverse } from 'lodash';
import Navbar from '../Layouts/Navbar';
function HospitalDesignation() {
  const authCtx = useContext(AuthContext);
  const [hospitalDesignation, setHospitalDesignation] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchHospitalDesignation();
  }, []);

  const fetchHospitalDesignation = async () => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    let response = await instance.get('/templates/designations', {
      headers: headers,
    });
    setHospitalDesignation(reverse(response.data.data));
    console.log(response.data);
  };

  const handleDelete = async (id) => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    let response = await instance.delete(`/templates/designations/${id}`, {
      headers: headers,
    });

    fetchHospitalDesignation();
  };

  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Allter Name',
      field: 'altName',
    },
    {
      title: 'Short Name',
      field: 'shortName',
    },
    {
      title: 'Hospital Code',
      field: 'code',
    },
    {
      title: 'Action',
      render: (rowData) => (
        <div>
          <Link
            to={`/hospitalDesignation/${rowData._id}`}
            className="btn btn-success me-3"
          >
            <i className="fa fa-edit"></i>
          </Link>
          <button
            onClick={() => handleDelete(rowData._id)}
            className="btn btn-danger"
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Navbar />
      <div className="px-4">
        <Link
          className="button-3 mt-2"
          // style={{ marginLeft: "50rem" }}
          to="/add-hospitalDesignation"
        >
          Add Designation
          <i className="ms-1 fa fa-chevron-right" aria-hidden="true"></i>
        </Link>
        <div>{error ? error : ''}</div>
        <MaterialTable
          title="Hospital Designations"
          style={{
            marginTop: '3rem',
            borderRadius: '16px',
            boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
          }}
          options={{ exportButton: true, grouping: true }}
          data={hospitalDesignation}
          columns={columns}
        ></MaterialTable>
      </div>
    </>
  );
}

export default HospitalDesignation;
