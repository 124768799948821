import Input from "../UI/input";
import React, { useEffect, useState, useCallback, useContext } from "react";
import LoadingSpinner from "../UI/LoadsingSpinner";
import Error from "../UI/Error";
import instance from "../../config/config";
import useInput from "../hooks/use-Input";
import AuthContext from "../store/authContext";
import Navbar from "../Layouts/Navbar";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";

const isNotEmpty = (value) => {
  return value.trim() !== "";
};

function HospitalDeptEdit(props) {
    let params = useParams();
    let id = params._id;
    const [loadedsingleHospitalDepartment, setLoadedSingleHospitalDepartment] =useState([]);
    const [error, setError] = useState(null);
    const [loadingSpinner, setloadingSpinner] = useState(false);
    const authCtx = useContext(AuthContext);
    const history = useHistory();
    const {
      value: enteredName,
      hasError: nameInputHasError,
      valueCHangeHandler: nameChangeHandler,
      inputBlurHandler: nameBlurHandler,
      isValid: enteredNameIsValid,
      reset: resetNameInput,
    } = useInput(isNotEmpty);
  
    const {
      value: enteredshortName,
      hasError: shortNameInputHasError,
      valueCHangeHandler: shortNameChangeHandler,
      inputBlurHandler: shortNameBlurHandler,
      isValid: enteredshortNameIsValid,
      reset: resetshortNameInput,
    } = useInput(isNotEmpty);
  
    //const id = ;
  
    const fetchSingleHospitalDepartment = useCallback(() => {
      setError(null);
      setloadingSpinner(true);
      let headers = {
        "x-access-token": authCtx.token,
        "Content-Type": "application/json",
      };
      instance
        .get("/templates/departments/" + id, { headers: headers })
        .then((response) => {
          setloadingSpinner(false);
          return response.data.data;
        })
        .then((data) => {
          const singleHospitalDepartment = {
            _id: data._id,
            name: data.name,
            shortName: data.shortName,
          };
  
          setLoadedSingleHospitalDepartment(singleHospitalDepartment);
        })
        .catch((err) => {
          setloadingSpinner(false);
          if (err.response.status == 401) {
            authCtx.logout();
          }
          setError(err.response.data.message || "Something went wrong!");
        });
    }, []);
  
    useEffect(() => {
      fetchSingleHospitalDepartment(id);
    }, [fetchSingleHospitalDepartment]);
  
    if (loadingSpinner) {
      return <LoadingSpinner />;
    }
  
    const submitHandler = (event) => {
      event.preventDefault();
  
      if (!enteredNameIsValid && !enteredshortNameIsValid) {
        return;
      }
  
      const id = loadedsingleHospitalDepartment._id;
  
      const department = {
        name: enteredName,
        shortName: enteredshortName,
      };
      let headers = {
        "x-access-token": authCtx.token,
        "Content-Type": "application/json",
      };
  
      instance
        .patch(
          "/templates/departments/" + id,
          { department },
          { headers: headers }
        )
        .then((response) => {
          history.push("/hospitalDepartment");
        })
        .catch((err) => {
          if (err.response.status == 401) {
            authCtx.logout();
          }
          setError(err.response.data.message || "Something went wrong!");
        });
    };
   // console.log(loadedsingleHospitalDepartment.name);
    return (
        <div>
        <Navbar/>
      <div className="container">
        <div className="row gutters">
          {props.error && <Error err={props.error} />}
          <form
            onSubmit={submitHandler}
            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
          >
            <div className="card h-100">
              <div className="card-body">
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 className="mb-2 text-primary">Personal Details</h6>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="hidden" value={loadedsingleHospitalDepartment._id} />
                      <Input
                        label="Name"
                        onChange={nameChangeHandler}
                        onBlur={nameBlurHandler}
                        input={{
                          name: "name",
                          type: "text",
                          defaultValue: loadedsingleHospitalDepartment.name,
                        }}
                      />

                      {nameInputHasError && (
                        <p className="text-danger"> Name Cannot Be Empty</p>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <Input
                        label="Short Name"
                        onChange={shortNameChangeHandler}
                        onBlur={shortNameBlurHandler}
                        input={{
                          type: "text",
                          name: "shortName",
                          defaultValue: loadedsingleHospitalDepartment.shortName,
                        }}
                      />

                      {shortNameInputHasError && (
                        <p className="text-danger"> Invalid Short Name</p>
                      )}
                    </div>
                  </div>
                  </div>
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="text-right">
                      <button
                        type="submit"
                        id="submit"
                        name="submit"
                        className="btn btn-primary mt-4"
                      >
                        Update
                      </button>
                      <Link to="/hospitalDepartment" className="btn btn-secondary" style={{marginLeft:'20px', marginTop:'22px'}}>Back</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    )
}

export default HospitalDeptEdit
