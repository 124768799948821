import React, { useEffect, useState, useContext } from 'react';
import MaterialTable from 'material-table';
import instance from '../../config/config';
//import {columns} from './static/hospitalColumn'
import AuthContext from '../store/authContext';
import { Link } from 'react-router-dom';
import _, { reverse } from 'lodash';
import Navbar from '../Layouts/Navbar';
function HospitalRole() {
  const authCtx = useContext(AuthContext);
  const [hospitalRole, setHospitalRole] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchHospitalRole();
  }, []);

  const fetchHospitalRole = async () => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    let response = await instance.get('/templates/roles', { headers: headers });
    setHospitalRole(reverse(response.data.data));
    console.log(response.data);
  };

  const handleDelete = async (id) => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    let response = await instance.delete(`/templates/roles/${id}`, {
      headers: headers,
    });

    fetchHospitalRole();
  };

  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Allter Name',
      field: 'altName',
    },
    {
      title: 'Short Name',
      field: 'shortName',
    },
    {
      title: 'Hospital Code',
      field: 'code',
    },
    {
      title: 'Action',
      render: (rowData) => (
        <div>
          <Link
            to={`/hospitalRole/${rowData._id}`}
            className="btn btn-success me-3"
          >
            <i className="fa fa-edit"></i>
          </Link>
          <button
            onClick={() => handleDelete(rowData._id)}
            className="btn btn-danger"
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Navbar />
      <div className="px-4">
        <div>
          <Link
            className="button-3 mt-3"
            // style={{ marginLeft: '50rem' }}
            to="/add-hospitalRole"
          >
            Add HospitalRole
            <i className="ms-1 fa fa-chevron-right" aria-hidden="true"></i>
          </Link>
        </div>
        <div>{error ? error : ''}</div>
        <MaterialTable
          title="Hospital Roles"
          style={{
            marginTop: '2rem',
            // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
            borderRadius: '10px',
          }}
          options={{ exportButton: true, grouping: true }}
          data={hospitalRole}
          columns={columns}
        ></MaterialTable>
      </div>
    </>
  );
}

export default HospitalRole;
