import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  PureComponent,
} from 'react';
import { Utils } from 'chart.js';

import Error from '../UI/Error';
import { useHistory, Link, useParams } from 'react-router-dom';
import instance from '../../config/config';
import MaterialTable from 'material-table';
import { reverse } from 'lodash';
import AuthContext from '../store/authContext';
import Navbar from '../Layouts/Navbar';
import LoadingSpinner from '../UI/LoadsingSpinner';

const Feedback = () => {
  const [error, setError] = useState(null);
  const [feedBackData, setFeedbackData] = useState([]);
  const [loadingSpinner, setloadingSpinner] = useState(false);

  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const params = useParams();
  let hospitalId = params.hospitalId;

  useEffect(() => {
    fetchHospitalFeedbacks(hospitalId);
  }, []);

  const fetchHospitalFeedbacks = useCallback(() => {
    setError(null);
    setloadingSpinner(true);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get(`/bd/dashboard/feedbacks`, {
        headers: headers,
      })
      .then((response) => {
        setFeedbackData(response.data.data.feedbacks);
        setloadingSpinner(false);
      })
      .catch((err) => {
        setloadingSpinner(false);
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setloadingSpinner(false);
        setError(err.message || 'Something went wrong!');
      });
  }, []);

  const feedbackStatusChangeHandler = (_id, hospitalId, event) => {
    const feedBackDetails = {
      status: event.target.value,
    };
    //  setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .patch(
        `/hospitals/${hospitalId}/dashboard/feedbacks/${_id}`,
        { feedBackDetails },
        { headers: headers }
      )
      .then((response) => {
        fetchHospitalFeedbacks(hospitalId);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const feedbackColumn = [
    {
      title: 'Title',
      field: 'title',
    },
    {
      title: 'Content',
      field: 'content',
    },
    {
      title: 'Hospital',
      field: 'hospital.name',
    },
    {
      title: 'First name',
      field: 'user.firstName',
    },
    {
      title: 'Last name',
      field: 'user.lastName',
    },
    {
      title: 'Mobile',
      field: 'user.mobile',
    },
    {
      title: 'Email',
      field: 'user.email',
    },
    {
      title: 'Status',
      field: 'status',
      type: 'boolean',
      render: (rowData) => (
        <select
          className="form-select"
          defaultValue={rowData?.status}
          onChange={(e) =>
            feedbackStatusChangeHandler(rowData._id, rowData.hospital._id, e)
          }
          aria-label="Default select example"
        >
          <option value="DEFAULT" disabled>
            Select
          </option>
          <option value="Reviewed">Reviewed</option>
          <option value="Implemented">Implemented</option>
          <option value="Not possible">Not possible</option>
        </select>
      ),
    },
  ];

  if (loadingSpinner) {
    return (
      <div className="centered">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Navbar />
      {error && <Error err={error} />}
      {!error && (
        <div className="row py-3 px-4">
          <h2 className="mb-2 px-4">Feedback Details</h2>
          <div className="col-md-4 col-xl-12 px-4">
            <MaterialTable
              title="Feedbacks"
              style={{
                marginTop: '1rem',
                // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
                borderRadius: '10px',
              }}
              data={feedBackData}
              columns={feedbackColumn}
              options={{
                pageSize: 4,
                // maxBodyHeight: 240,
                headerStyle: { color: 'black', fontSize: '15px' },
                // exportButton: true,
                // grouping: true,
                //rowStyle: { backgroundColor: 'green'},
              }}
            ></MaterialTable>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default Feedback;
