import React, { useState, useEffect, useContext, useCallback } from 'react';
import instance from '../../config/config';
import Table from '../Table/Table';
import Navbar from '../Layouts/Navbar';
import MaterialTable from 'material-table';
import { reverse } from 'lodash';

import AuthContext from '../store/authContext';
import Error from '../UI/Error';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
const Resources = () => {
  const [modal, showModal] = useState(false);
  const [resourcesName, setResources] = useState('');
  const [loadedResources, setloadedResources] = useState('');
  const [error, setError] = useState('');

  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const modalHandler = () => {
    showModal(true);
  };

  const closeModalHandler = () => {
    showModal(false);
  };

  const resourceChangeHandler = (event) => {
    setResources(event.target.value);
  };

  const fetchResources = useCallback(() => {
    // setError(null);
    // setloadingSpinner(true);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get('/acl/resources', { headers: headers })
      .then((response) => {
        const updatedData = response.data.data.map((data) => {
          return data;
        });
        return updatedData;
      })
      .then((data) => {
        setloadedResources(data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.response.data.message || 'Something went wrong!');
      });
  }, []);

  useEffect(() => {
    fetchResources();
  }, [fetchResources]);

  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Alter Name',
      field: 'alterName',
    },

    {
      title: 'Action',
      render: (rowData) => (
        <Link to={`/resources/${rowData._id}`} className="btn btn-success">
          <i class="fa fa-edit"></i>
        </Link>
      ),
    },
  ];

  const formSumbitHandler = (event) => {
    const resourses = {
      newApiResourses: [resourcesName],
    };

    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .post('/acl/resources', { resourses }, { headers: headers })
      .then((response) => {
        fetchResources();
      })
      .catch((err) => {
        setError(err.response.data.message || 'Something went wrong!');
      });
    showModal(false);
  };
  // let content;
  // if (loadedResources) {
  //   content = loadedResources.map((resources) => {
  //     return (
  //       <tr key={resources.id}>
  //         <td>{resources.name}</td>
  //         <td>{resources.alterName}</td>
  //       </tr>
  //     );
  //   });
  // }

  return (
    <>
      <Navbar />
      <div>
        {error && <Error err={error} />}
        {modal && (
          <div className="modal-dialog modal-dialog-centered" tabindex="-1">
            <div className="modal-dialog  w-75">
              <div className="modal-content">
                <form onSubmit={formSumbitHandler} action="">
                  <div className="modal-header">
                    <h5 className="modal-title">Add Resources</h5>

                    <button
                      onClick={closeModalHandler}
                      type="button"
                      class="btn-close"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <input
                      onChange={resourceChangeHandler}
                      className="form-control"
                      type="text"
                    />
                  </div>
                  <div className="modal-footer">
                    <button
                      onClick={closeModalHandler}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {!modal && loadedResources && (
          <div className="px-4">
            <button
              onClick={modalHandler}
              className="button-3 mt-3"
              // style={{ marginLeft: '50rem' }}
              // to="/add-hospitalDesignation"
            >
              Add Resource
              <i className=" ms-1 fa fa-chevron-right" aria-hidden="true"></i>
            </button>
            <div>{error ? error : ''}</div>
            <div className="col-xl-12 col-log-12 col-md-6">
              <MaterialTable
                title="ACL Resource"
                style={{
                  marginTop: '2rem',
                  // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
                  borderRadius: '10px',
                }}
                data={reverse(loadedResources)}
                columns={columns}
              ></MaterialTable>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Resources;
