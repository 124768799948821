import React, { useState, useEffect, useContext, useCallback } from 'react';
import instance from '../../config/config';
import { useHistory, Link } from 'react-router-dom';
import AuthContext from '../store/authContext';
import MaterialTable from 'material-table';
import Error from '../UI/Error';
import Navbar from '../Layouts/Navbar';
import { Button } from '@material-ui/core';
import OnToggleButton from '../UI/OnToggle';
import OfToggleButton from '../UI/OfToggle';
const Roles = () => {
  const [modal, showModal] = useState(false);
  const [roleName, setRole] = useState('');
  const [loadedRoles, setloadedRoles] = useState('');
  const [error, setError] = useState('');

  const history = useHistory();

  const authCtx = useContext(AuthContext);

  const modalHandler = () => {
    showModal(true);
  };

  const closeModalHandler = () => {
    showModal(false);
  };

  const modalSync = () => {
    fetchRoles();
  };

  const roleChangeHandler = (event) => {
    setRole(event.target.value);
  };

  const aclRolesHandler = (id) => {
    history.push('/permissions/' + id);
  };
  const fetchRoles = useCallback(() => {
    // setError(null);
    // setloadingSpinner(true);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get('/acl/roles', { headers: headers })
      .then((response) => {
        setloadedRoles(response.data.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.response.data.message || 'Something went wrong!');
      });
  }, []);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const statusChangeHandler = (status, id) => {
    const role = {
      active: !status,
    };

    //  setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };

    instance
      .patch('acl/roles/' + id, { role }, { headers: headers })
      .then((response) => {
        console.log(response);
        // history.push("/hospital/" + hospitalid);
        fetchRoles();
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const columns = [
    {
      title: 'Name',
      field: 'name',
      flex: 1,
      width: 100,
    },
    {
      title: 'Status',
      field: 'active',
      type: 'boolean',
      flex: 1,
      width: 100,
      render: (rowData) => (
        <div>
          {rowData.active && (
            <span className="badge badge-success">active</span>
          )}
          {!rowData.active && (
            <span className="badge badge-danger">Inactive</span>
          )}

          {rowData.active && (
            <OnToggleButton
              onClick={statusChangeHandler.bind(
                this,
                rowData.active,
                rowData._id
              )}
            />
          )}
          {!rowData.active && (
            <OfToggleButton
              onClick={statusChangeHandler.bind(
                this,
                rowData.active,
                rowData._id
              )}
            />
          )}
        </div>
      ),
    },

    {
      title: 'Permissions',
      flex: 1,
      width: 100,
      render: (rowData) => (
        <Link to={`/permissions/${rowData._id}`} className="btn btn-primary">
          <i class="fa fa-eye" aria-hidden="true"></i>
        </Link>
      ),
    },

    {
      title: 'Action',
      flex: 1,
      width: 100,
      render: (rowData) => (
        <Link to={`/roles/${rowData._id}`} className="btn btn-success">
          <i class="fa fa-edit"></i>
        </Link>
      ),
    },
  ];

  const formSumbitHandler = (event) => {
    event.preventDefault();
    const role = {
      name: roleName,
    };

    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .post('/acl/roles', { role }, { headers: headers })
      .then((response) => {
        fetchRoles();
      })
      .catch((err) => {
        setError(err.response.data.message || 'Something went wrong!');
      });

    showModal(false);
  };
  // let content;
  // if (loadedRoles) {
  //   content = loadedRoles.map((roles) => {
  //     return (
  //       <tr key={roles._id}>
  //         <td
  //           key={roles._id}
  //           onClick={aclRolesHandler.bind(this, roles._id)}
  //           style={{ cursor: "pointer" }}
  //         >
  //           {roles.name}
  //         </td>
  //       </tr>
  //     );
  //   });
  // }

  return (
    <>
      <Navbar />
      <div>
        {error && <Error err={error} />}
        {modal && (
          <div className="modal-dialog modal-dialog-centered" tabindex="-1">
            <div className="modal-dialog  w-75">
              <div className="modal-content">
                <form onSubmit={formSumbitHandler} action="">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      <i className="fa fa-address-book" aria-hidden="true"></i>
                      Add Role
                    </h5>

                    <button
                      onClick={closeModalHandler}
                      type="button"
                      class="btn-close"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <input
                      onChange={roleChangeHandler}
                      className="form-control"
                      type="text"
                    />
                  </div>
                  <div class="modal-footer">
                    <button
                      onClick={closeModalHandler}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {!modal && loadedRoles && (
          // <div className="row p-4">
          <div className="px-4">
            <button
              onClick={modalHandler}
              className="button-3 mt-3"
              // style={{ marginLeft: '51rem' }}
              // to="/add-hospitalDesignation"
            >
              Add Role
              <i className="ms-1 fa fa-chevron-right" aria-hidden="true"></i>
            </button>
            <div>{error ? error : ''}</div>
            <div className="col-md-6 col-xl-12">
              <MaterialTable
                title="ACL Roles"
                data={loadedRoles}
                columns={columns}
                options={{
                  // exportButton: true,
                  // grouping: true,
                  //rowStyle: { backgroundColor: 'green'},
                  rowStyle: (rowData) => {
                    if (!rowData.active) {
                      return { backgroundColor: '#BFBFBF' };
                    }

                    return {};
                  },
                }}
                style={{
                  marginTop: '1rem',
                  // width: '100%',
                  // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
                  borderRadius: '10px',
                }}
              ></MaterialTable>
            </div>
          </div>
          // </div>
        )}
      </div>
    </>
  );
};

export default Roles;
