import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  PureComponent,
} from 'react';
import { Utils } from 'chart.js';

import Error from '../UI/Error';
import { useHistory, Link, useParams } from 'react-router-dom';
import instance from '../../config/config';
import OfToggleButton from '../UI/OfToggle';
import MaterialTable from 'material-table';
import swal from 'sweetalert';
import OnToggleButton from '../UI/OnToggle';
import AuthContext from '../store/authContext';
import Navbar from '../Layouts/Navbar';
import '../Table/Table.css';
import Graph from '../chart/Graph';

const User = () => {
  const [users, setUserdata] = useState([]);
  const [error, setError] = useState(null);
  const [HospitalData, setHospitalData] = useState([]);
  const [departmentsData, setDepartmentData] = useState([]);
  const [bedsData, setBedsData] = useState([]);
  const [wardsData, setWardsData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [feedBackData, setFeedbackData] = useState([]);
  const [hospital, setHospital] = useState(null);
  const [requests, setRequests] = useState([]);

  const [loadingSpinner, setloadingSpinner] = useState(false);

  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const params = useParams();
  let hospitalId = params.hospitalId;

  const fetchUsers = useCallback(async (hospitalId) => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get(`/hospitals/${hospitalId}/users?type=accesscontrol`, {
        headers: headers,
      })
      .then((response) => {
        setUserdata(response.data.data.users);
      })
      .catch((err) => {
        // if (err?.response?.status == 401) {
        //   authCtx.logout();
        // }
        // setError(err.message);
      });
  }, []);

  const fetchCurrentAdmins = useCallback(async (hospitalId) => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get(`/hospitals/${hospitalId}/access`, {
        headers: headers,
      })
      .then((response) => {
        setAdminData(response.data.data.users);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          authCtx.logout();
        }
        setError(err.message);
      });
  }, []);

  const fetchDepartments = useCallback(async (hospitalId) => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get(`/hospitals/${hospitalId}/departments`, {
        headers: headers,
      })
      .then((response) => {
        console.log({ response });

        setDepartmentData(response.data.data);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          authCtx.logout();
        }
        setError(err.message);
      });
  }, []);

  const fetchWards = useCallback(async (hospitalId) => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get(`/hospitals/${hospitalId}/wards`, {
        headers: headers,
      })
      .then((response) => {
        console.log({ response });

        setWardsData(response.data.data);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          authCtx.logout();
        }
        setError(err.message);
      });
  }, []);

  const fetchBeds = useCallback(async (hospitalId) => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get(`/hospitals/${hospitalId}/wards/beds`, {
        headers: headers,
      })
      .then((response) => {
        setBedsData(response.data.data.allBeds);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          authCtx.logout();
        }
        setError(err.message);
      });
  }, []);

  const fetchHospital = useCallback(async (hospitalId) => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get(`/hospitals/${hospitalId}`, {
        headers: headers,
      })
      .then((response) => {
        setHospital(response.data.data);
        setRequests(response?.data?.data?.requests);
        //   setBedsData(response.data.data);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          authCtx.logout();
        }
        setError(err.message);
      });
  }, []);

  const fetchHospitalsData = useCallback(() => {
    setError(null);
    setloadingSpinner(true);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get(`/hospitals/${hospitalId}/dashboard`, {
        headers: headers,
      })
      .then((response) => {
        console.log(response);
        setloadingSpinner(false);
        console.log({ response });
        setHospitalData(response.data.data);
      })
      .catch((err) => {
        setloadingSpinner(false);
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.message || 'Something went wrong!');
      });
  }, []);

  const fetchHospitalFeedbacks = useCallback(() => {
    setError(null);
    setloadingSpinner(true);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get(`/bd/dashboard/feedbacks?hospitalId=${hospitalId}`, {
        headers: headers,
      })
      .then((response) => {
        setloadingSpinner(false);

        setFeedbackData(response.data.data.feedbacks);
      })
      .catch((err) => {
        setloadingSpinner(false);
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.message || 'Something went wrong!');
      });
  }, []);

  let data = [
    { name: 'Beds', value: HospitalData.beds },
    { name: 'Departments', value: HospitalData.departments },
    { name: 'Patients', value: HospitalData.patients },
    { name: 'Employees', value: HospitalData.employees },
  ];

  useEffect(() => {
    fetchHospital(hospitalId);
    fetchHospitalsData(hospitalId);
    fetchUsers(hospitalId);
    fetchCurrentAdmins(hospitalId);
    fetchDepartments(hospitalId);
    fetchWards(hospitalId);
    fetchBeds(hospitalId);
    fetchHospitalFeedbacks(hospitalId);
  }, [fetchUsers]);

  const statusChangeHandler = (status, userId, type) => {
    swal({
      title: !status ? 'Suspend User' : 'Activate User',
      text: `Are you sure you want ${
        !status ? 'suspend' : 'activate'
      } this user?`,
      buttons: true,
      dangerMode: true,
    }).then((willSuspend) => {
      if (willSuspend) {
        let url = '';

        if (status) {
          url = `/bd/activate?type=${type}`;
        } else {
          url = `/bd/suspend?type=${type}`;
        }
        const hospital = {
          hospitalId: hospitalId,
          userId: userId,
        };
        //  setError(null);
        let headers = {
          'x-access-token': authCtx.token,
          'Content-Type': 'application/json',
        };

        instance
          .post(url, hospital, { headers: headers })
          .then((response) => {
            if (type === 'ADMINS') {
              setAdminData(response.data.data.allUsers);
            } else {
              // setUserdata(response.data.data.allUsers);
              fetchUsers(hospitalId);
            }
          })
          .catch((err) => {
            setError(err.message);
          });
      }
    });
  };

  const hospitalStatusChangeHandler = (status, type) => {
    let url = `/bd/hospital/status`;

    const hospital = {
      hospitalId: hospitalId,
      type,
      status: status,
    };
    //  setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };

    instance
      .post(url, hospital, { headers: headers })
      .then((response) => {
        setRequests(response?.data?.data?.requests);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const feedbackStatusChangeHandler = (_id, hospitalid, event) => {
    // const feedBackDetails = {
    //   status: event.target.value,
    // };
    // //  setError(null);
    // let headers = {
    //   'x-access-token': authCtx.token,
    //   'Content-Type': 'application/json',
    // };
    // instance
    //   .patch(
    //     `/hospitals/${hospitalId}/dashboard/feedbacks/${_id}`,
    //     { feedBackDetails },
    //     { headers: headers }
    //   )
    //   .then((response) => {
    //     fetchHospitalFeedbacks(hospitalId);
    //   })
    //   .catch((err) => {
    //     setError(err.message);
    //   });
  };

  const deleteUser = (userId, type) => {
    swal({
      title: 'Delete User',
      text: `Are you sure you want delete this user?`,
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let url = `/bd/dashboard/hospitals/${hospitalId}/users/${userId}?type=${type}`;

        console.log(authCtx?.token);
        //  setError(null);
        let headers = {
          'x-access-token': authCtx.token,
          'Content-Type': 'application/json',
        };

        instance
          .delete(url, { headers: headers })
          .then((response) => {
            // history.push("/hospital/" + hospitalid);
            setUserdata(response.data.data.allUsers);
            setAdminData(response.data.data.adminUsers);
          })
          .catch((err) => {
            setError(err.message);
          });
      }
    });
  };

  const suspendHospital = () => {
    swal({
      title: 'Suspend Hospital',
      text: `Are you sure you want suspend this hospital?`,
      buttons: true,
      dangerMode: true,
    }).then((willSuspend) => {
      if (willSuspend) {
        let url = `/bd/hospitals/${hospitalId}/suspend`;

        //  setError(null);
        let headers = {
          'x-access-token': authCtx.token,
          'Content-Type': 'application/json',
        };

        instance
          .post(url, {}, { headers: headers })
          .then((response) => {
            setRequests(response.data.data.requests);
            setUserdata(response.data.data.allUsers);
            setAdminData(response.data.data.adminUsers);
          })
          .catch((err) => {
            setError(err.message);
          });
      }
    });
  };

  const activateHospital = () => {
    swal({
      title: 'Activate Hospital',
      text: `Are you sure you want activate this hospital?`,
      buttons: true,
      dangerMode: true,
    }).then((willSuspend) => {
      if (willSuspend) {
        let url = `/bd/hospitals/${hospitalId}/activate`;

        //  setError(null);
        let headers = {
          'x-access-token': authCtx.token,
          'Content-Type': 'application/json',
        };

        instance
          .post(url, {}, { headers: headers })
          .then((response) => {
            setRequests(response.data.data.requests);
            setUserdata(response.data.data.allUsers);
            setAdminData(response.data.data.adminUsers);
          })
          .catch((err) => {
            setError(err.message);
          });
      }
    });
  };

  const columns = [
    {
      title: 'Name',
      field: 'fullName',
    },
    {
      title: 'Country',
      field: 'countryCode',
    },

    {
      title: 'Mobile',
      field: 'mobile',
    },

    {
      title: 'Designation',
      field: 'designation',
    },
    {
      title: 'Department',
      field: 'department',
    },
    {
      title: 'HospitalRole',
      field: 'hospitalRole',
    },
    {
      title: 'Registration',
      field: 'createdAt',
      type: 'date',
    },

    {
      title: 'Status',
      field: 'suspendedBybd',
      type: 'boolean',
      render: (rowData) => (
        <div>
          {!rowData.suspendedBybd && (
            <span className=" badge badge-success">active</span>
          )}
          {rowData.suspendedBybd && (
            <span className="badge badge-danger">Inactive</span>
          )}

          {!rowData.suspendedBybd && (
            <OnToggleButton
              onClick={statusChangeHandler.bind(
                this,
                rowData.suspendedBybd,
                rowData._id,
                'USERS'
              )}
            />
          )}
          {rowData.suspendedBybd && (
            <OfToggleButton
              onClick={statusChangeHandler.bind(
                this,
                rowData.suspendedBybd,
                rowData._id,
                'USERS'
              )}
            />
          )}
        </div>
      ),
    },

    {
      title: 'Actions',
      render: (rowData) => (
        <div>
          <button
            onClick={() => deleteUser(rowData?._id, 'USERS')}
            className="btn btn-danger"
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];
  const graphData = {
    labels: ['Beds', 'Departments', 'Patients', 'Employees'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [
          HospitalData.beds,
          HospitalData.departments,
          HospitalData.patients,
          HospitalData.employees,
        ],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)',
        ],
        hoverOffset: 4,
      },
    ],
  };

  const adminColumns = [
    {
      title: 'Name',
      field: 'fullName',
    },
    {
      title: 'Country',
      field: 'countryCode',
    },

    {
      title: 'Mobile',
      field: 'mobile',
    },
    {
      title: 'Role',
      field: 'role',
    },
    {
      title: 'Registration',
      field: 'createdAt',
      type: 'date',
    },
    {
      title: 'Onboarded',
      field: 'webOnboarded',
      render: (rowData) => (
        <div>
          {rowData.webOnboarded ? (
            <i className="fa fa-check"></i>
          ) : (
            <i class="fa fa-close"></i>
          )}
        </div>
      ),
    },

    {
      title: 'Status',
      field: 'suspendedBybd',
      type: 'boolean',
      render: (rowData) => (
        <div>
          {!rowData.suspendedBybd && (
            <span className=" badge badge-success">active</span>
          )}
          {rowData.suspendedBybd && (
            <span className="badge badge-danger">Inactive</span>
          )}

          {!rowData.suspendedBybd && (
            <OnToggleButton
              onClick={statusChangeHandler.bind(
                this,
                rowData.suspendedBybd,
                rowData._id,
                'ADMINS'
              )}
            />
          )}
          {rowData.suspendedBybd && rowData.suspendedBybd && (
            <OfToggleButton
              onClick={statusChangeHandler.bind(
                this,
                rowData.suspendedBybd,
                rowData._id,
                'ADMINS'
              )}
            />
          )}
        </div>
      ),
    },

    {
      title: 'Actions',
      render: (rowData) => (
        <div>
          <button
            onClick={() => deleteUser(rowData?._id, 'ADMINS')}
            className="btn btn-danger"
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const departmentColumn = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Code',
      field: 'code',
    },
  ];

  const bedColumn = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Code',
      field: 'code',
    },
    {
      title: 'Ward',
      field: 'wardName',
    },
    {
      title: 'Status',
      field: 'active',
      type: 'boolean',
      render: (rowData) => (
        <div>
          {rowData.occupied && (
            <span className=" badge badge-success">Occupied</span>
          )}
          {!rowData.occupied && (
            <span className="badge badge-danger">Vaccant</span>
          )}
        </div>
      ),
    },
  ];

  const feedbackColumn = [
    {
      title: 'Title',
      field: 'title',
    },
    {
      title: 'Content',
      field: 'content',
    },
    {
      title: 'First name',
      field: 'user.firstName',
    },
    {
      title: 'Last name',
      field: 'user.lastName',
    },
    {
      title: 'Mobile',
      field: 'user.mobile',
    },
    {
      title: 'Email',
      field: 'user.email',
    },
    {
      title: 'Status',
      field: 'status',
      type: 'boolean',
      render: (rowData) => (
        <select
          className="form-select"
          defaultValue={rowData?.status}
          onChange={(e) =>
            feedbackStatusChangeHandler(rowData._id, rowData.hospital._id, e)
          }
          aria-label="Default select example"
        >
          <option value="DEFAULT" disabled>
            Select
          </option>
          <option value="Reviewed">Reviewed</option>
          <option value="Implemented">Implemented</option>
          <option value="Not possible">Not possible</option>
        </select>
      ),
    },
  ];

  const renderRequest = (rowData, requestType) => {
    const status = rowData['requestStatus']; // e.g., transferRequestStatus
    console.log({ status });
    const isProcessed = status === 'PROCESSED';

    return (
      <div>
        {/* Status Badge */}
        <div>
          {status === 'REVIEWED' && (
            <span className="badge badge-pill badge-primary">Reviewed</span>
          )}
          {status === 'UNREVIEWED' && (
            <span className="badge badge-pill badge-secondary">Unreviewed</span>
          )}
          {isProcessed && (
            <span className="badge badge-pill badge-success">Processed</span>
          )}
        </div>

        {/* Toggle Button */}
        {!isProcessed &&
          (status === 'UNREVIEWED' ? (
            <OfToggleButton
              onClick={() =>
                hospitalStatusChangeHandler('UNREVIEWED', rowData?.type)
              }
            />
          ) : (
            <OnToggleButton
              onClick={() =>
                hospitalStatusChangeHandler('REVIEWED', rowData?.type)
              }
            />
          ))}
      </div>
    );
  };

  const requestColumn = [
    {
      title: 'Type',
      field: 'type',
    },
    {
      title: 'Hospital',
      field: 'hospital',
    },
    {
      title: 'From email',
      field: 'adminEmail',
    },
    {
      title: 'From Mobile',
      field: 'adminMobile',
    },
    {
      title: 'New Admin',
      field: 'transferDetails.fullName',
    },

    {
      title: 'To Mobile',
      field: 'transferDetails.mobile',
    },
    {
      title: 'To Email',
      field: 'transferDetails.email',
    },
    {
      title: 'Status',
      field: 'transferDetails',

      render: (rowData) => renderRequest(rowData, rowData?.type),
    },
    {
      title: 'Actions',
      render: (rowData) => (
        <div>
          {/* Transfer Request Button */}
          {rowData?.type === 'Transfer' && (
            <button
              // onClick={() =>
              //   rowData.requestStatus !== 'processed' &&
              //   deleteUser(rowData?._id, 'ADMINS')
              // }
              className="btn btn-danger"
              disabled={rowData.requestStatus === 'processed'}
            >
              {rowData.requestStatus === 'processed' ? 'Processed' : 'Transfer'}
            </button>
          )}

          {/* Suspend Request Button */}
          {rowData?.type === 'Suspend' && (
            <button
              onClick={() =>
                rowData.suspendedBybd ? activateHospital() : suspendHospital()
              }
              className={`btn ${
                rowData.suspendedBybd ? 'btn-success' : 'btn-danger'
              }`}
            >
              {rowData.suspendedBybd ? 'Activate' : 'Suspend'}
            </button>
          )}

          {/* Delete Request Button */}
          {rowData?.type === 'Delete' && (
            <button
              disabled={true}
              onClick={() => deleteUser(rowData?._id, 'ADMINS')}
              className="btn btn-danger"
            >
              Delete
            </button>
          )}
        </div>
      ),
    },
  ];

  function transparentize(r, g, b, alpha) {
    const a = (1 - alpha) * 255;
    const calc = (x) => Math.round((x - a) / alpha);

    return `rgba(${calc(r)}, ${calc(g)}, ${calc(b)}, ${alpha})`;
  }

  const DATA_COUNT = 7;
  const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };
  function number(config) {
    var cfg = config || {};
    var min = cfg.min || 0;
    var max = cfg.max || 1;
    var from = cfg.from || [];
    var count = cfg.count || 8;
    var decimals = cfg.decimals || 8;
    var continuity = cfg.continuity || 1;
    var dfactor = Math.pow(10, decimals) || 0;
    var data = [];
    var i, value;

    for (i = 0; i < count; ++i) {
      value = (from[i] || 0) + Math.random(min, max);
      if (Math.random() <= continuity) {
        data.push(Math.round(dfactor * value) / dfactor);
      } else {
        data.push(null);
      }
    }
    console.log({ data });
    return data;
  }

  const labels = ['January', 'February', 'March', 'April'];
  const lineData = {
    labels: labels,
    datasets: [
      {
        label: 'Texts',
        data: [30, 40, 10, 50],
        borderColor: 'rgb(255, 99, 132)',
        // tension: 0.1,
        // backgroundColor: transparentize(255, 99, 132, 0.5),
      },
      {
        label: 'Videos',
        data: [50, 70, 30, 20],
        // borderColor: 'rgb(255, 159, 64)',
        borderColor: 'rgb(75, 192, 192)',
        // tension: 0.1,
        // backgroundColor: transparentize(54, 162, 135, 0.5),
      },
      {
        label: 'Audios',
        data: [10, 5, 45, 70],
        borderColor: 'rgb(255, 205, 86)',
        // tension: 0.1,
        // backgroundColor: transparentize(54, 162, 135, 0.5),
      },
      {
        label: 'Documents',
        data: [10, 50, 45, 40],
        borderColor: 'rgb(54, 162, 135)',
        // tension: 0.1,
        // backgroundColor: transparentize(54, 162, 135, 0.5),
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    aspectRatio: 2,
    // weight: 00,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Hospital Details',
      },
    },
  };

  return (
    <React.Fragment>
      <Navbar />
      {error && <Error err={error} />}
      {!error && (
        <div>
          <div className="py-3 px-4">
            <button
              onClick={() => history.goBack()}
              style={{ cursor: 'pointer' }}
              type="button"
              className="btn btn-primary"
            >
              Go Back
            </button>
          </div>
          <div className="row py-3 px-4">
            <h2 className="mb-3">{HospitalData?.name}</h2>
            <div className="col-xl-3 col-md-6">
              <div className="card border-start border-3 border-primary py-2 ">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-1">
                      <div className="text-xs font-weight-bold text-primary text-uppercase ">
                        Employees
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800 mt-2">
                        {HospitalData.employees}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fa fa-hospital-o fa-2x text-primary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="card  border-start border-3 border-success  py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-1">
                      <div className="text-xs font-weight-bold text-success text-uppercase ">
                        Departments
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800 mt-2">
                        {HospitalData.departments}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fa fa-medkit fa-2x text-success"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="card  border-start border-3 border-info   py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-1">
                      <div className="text-xs font-weight-bold text-info text-uppercase ">
                        Beds
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800 mt-2">
                        {HospitalData.beds}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fa fa-user fa-2x text-info"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="card  border-start border-3 border-warning  py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-1">
                      <div className="text-xs font-weight-bold text-warning text-uppercase ">
                        Patients
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800 mt-2">
                        {HospitalData.patients}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fa fa-plus-square fa-2x text-warning"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row px-4 py-3">
            <div className="col-xl-6 col-md-4  ">
              <div className="card shadow-none">
                <div className="card-body">
                  <Graph
                    className="col-md-12"
                    maxHeight="500px"
                    type="doughnut"
                    options={doughnutOptions}
                    data={graphData}
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-md-4">
              <div className="card shadow-none">
                <div className="card-body">
                  <Graph
                    className="col-md-12"
                    maxHeight="500px"
                    type="line"
                    data={lineData}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-12 px-4 ">
            <MaterialTable
              title="Users"
              style={{
                marginTop: '1rem',
                // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
                borderRadius: '12px',
              }}
              data={users}
              columns={columns}
              options={{
                pageSize: 4,
                // maxBodyHeight: 240,
                headerStyle: { color: 'black', fontSize: '15px' },
                exportButton: true,
                // grouping: true,
                //rowStyle: { backgroundColor: 'green'},
                rowStyle: (rowData) => {
                  if (rowData.suspendedBybd) {
                    return { backgroundColor: '#BFBFBF' };
                  }

                  return {};
                },
              }}
            ></MaterialTable>
          </div>
          <div className="col-md-6 col-xl-12 px-4">
            <MaterialTable
              title="Admins"
              style={{
                marginTop: '1rem',
                // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
                borderRadius: '10px',
              }}
              data={adminData}
              columns={adminColumns}
              options={{
                // filtering: true,
                pageSize: 4,
                // maxBodyHeight: 240,
                headerStyle: { color: 'black', fontSize: '15px' },
                // exportButton: true,
                // grouping: true,
                //rowStyle: { backgroundColor: 'green'},
                rowStyle: (rowData) => {
                  if (rowData.suspendedBybd) {
                    return { backgroundColor: '#BFBFBF' };
                  }

                  return {};
                },
              }}
            ></MaterialTable>
          </div>
          <div className="row px-4">
            <div className="col-md-6 col-xl-6">
              <MaterialTable
                title="Departments"
                style={{
                  marginTop: '1rem',
                  // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
                  borderRadius: '10px',
                }}
                data={departmentsData}
                columns={departmentColumn}
                options={{
                  pageSize: 4,
                  // maxBodyHeight: 240,
                  headerStyle: { color: 'black', fontSize: '15px' },
                  //   exportButton: true,
                  // grouping: true,
                  //rowStyle: { backgroundColor: 'green'},
                }}
              ></MaterialTable>
            </div>
            <div className="col-md-6 col-xl-6">
              <MaterialTable
                title="Wards"
                style={{
                  marginTop: '1rem',
                  // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
                  borderRadius: '10px',
                }}
                data={wardsData}
                columns={departmentColumn}
                options={{
                  pageSize: 4,
                  // maxBodyHeight: 240,
                  headerStyle: { color: 'black', fontSize: '15px' },
                  //   exportButton: true,
                  // grouping: true,
                  //rowStyle: { backgroundColor: 'green'},
                }}
              ></MaterialTable>
            </div>
          </div>

          <div className="col-md-4 col-xl-12 px-4">
            <MaterialTable
              title="Beds"
              style={{
                marginTop: '1rem',
                // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
                borderRadius: '10px',
              }}
              data={bedsData}
              columns={bedColumn}
              options={{
                pageSize: 4,
                // maxBodyHeight: 240,
                headerStyle: { color: 'black', fontSize: '15px' },
                // exportButton: true,
                // grouping: true,
                //rowStyle: { backgroundColor: 'green'},
              }}
            ></MaterialTable>
          </div>
          <div className="col-md-4 col-xl-12 px-4">
            <MaterialTable
              title="Feedbacks"
              style={{
                marginTop: '1rem',
                // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
                borderRadius: '10px',
              }}
              data={feedBackData}
              columns={feedbackColumn}
              options={{
                pageSize: 4,
                // maxBodyHeight: 240,
                headerStyle: { color: 'black', fontSize: '15px' },
                // exportButton: true,
                // grouping: true,
                //rowStyle: { backgroundColor: 'green'},
              }}
            ></MaterialTable>
          </div>
          <div className="col-md-6 col-xl-12 px-4">
            <MaterialTable
              title="Hospital Requests"
              style={{
                marginTop: '1rem',
                // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
                borderRadius: '10px',
              }}
              data={requests}
              columns={requestColumn}
              options={{
                // filtering: true,
                pageSize: 4,
                // maxBodyHeight: 240,
                headerStyle: { color: 'black', fontSize: '15px' },
              }}
            ></MaterialTable>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default User;
